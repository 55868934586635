import React, { useEffect, useState } from "react";
import { CommentOutlined } from "@ant-design/icons";
import { Badge, Button, Form, Input, Modal, Tooltip } from "antd";
import { useAppSelector } from "hooks";
import { AuditScheduleTask } from "modules/auditSchedule/types";

const { TextArea } = Input;

interface Props {
    orgId: string;
    auditId: string;
    taskId: string;
    updateAuditScheduleTask: (
        data: Pick<AuditScheduleTask, "issueDescription">,
        id: string,
    ) => void;
    disabled?: boolean;
}

export default function AuditTaskNoteAction({
    orgId,
    auditId,
    taskId,
    disabled,
    updateAuditScheduleTask,
}: //
Props) {
    console.log({ orgId, auditId, taskId });
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const task = useAppSelector(
        state => state.auditSchedules.currentAuditSchedule.tasks[taskId],
    );
    const [issueDescription, setIssueDescription] = useState(task.issueDescription);
    // const [form] = Form.useForm();

    useEffect(() => {
        if (open) {
            setIssueDescription(task.issueDescription);
        }
    }, [open]);
    console.log({ task });
    const showModal = () => {
        setOpen(true);
    };

    const handleOk = () => {
        updateAuditScheduleTask({ issueDescription: issueDescription || "" }, taskId);
        setOpen(false);
    };

    const handleCancel = () => {
        console.log("Clicked cancel button");
        setOpen(false);
    };

    return (
        <>
            <Tooltip title={task.issueDescription}>
                <Button
                    disabled={disabled}
                    onClick={showModal}
                    icon={
                        <Badge dot={Boolean(task.issueDescription)}>
                            <CommentOutlined />
                        </Badge>
                    }>
                    Note
                </Button>
            </Tooltip>

            <Modal
                destroyOnClose
                title="Tilføj note til opgaven"
                open={open}
                onOk={handleOk}
                okText="Gem"
                cancelText="Fortryd"
                confirmLoading={confirmLoading}
                onCancel={handleCancel}>
                <div>
                    <TextArea
                        value={issueDescription}
                        placeholder="Noter"
                        onChange={e => setIssueDescription(e.target.value)}
                    />
                </div>
            </Modal>
        </>
    );
}
