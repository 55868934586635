import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useTranslation } from "react-i18next";
import { AuditCompleteState, AuditType } from "@aks2tal/shared";
import { updateAuditSchedule } from "modules/auditSchedule/actions";

export default function AuditMarkAsVoided({
    orgId,
    auditId,
}: {
    orgId: string;
    auditId: string;
}) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const isMachineAudit = useAppSelector(
        state => state.auditSchedules.currentAuditSchedule?.type === AuditType.MACHINE,
    );
    const isVoided = useAppSelector(
        state =>
            state.auditSchedules.currentAuditSchedule?.state ===
            AuditCompleteState.VOIDED,
    );

    const auditorId = useAppSelector(state => state.user.details.uid);

    const isCompleted = useAppSelector(
        state => state.auditSchedules.currentAuditSchedule?.isCompleted,
    );
    if (!isMachineAudit) {
        return null;
    }

    /**
     * TODO: should we check with them if tasks are not checked if that is on purpose?
     * If the is any tasks?
     */
    const handleToggleVoidedAudit = () => {
        try {
            if (!auditId || !orgId) return;

            dispatch(
                updateAuditSchedule({
                    orgId,
                    auditScheduleId: auditId,
                    data: {
                        isCompleted: !isCompleted,
                        completeTime: new Date(),
                        auditor: auditorId,
                        state: isCompleted
                            ? AuditCompleteState.COMPLETE
                            : AuditCompleteState.VOIDED,
                    },
                }),
            );
        } catch (error) {
            console.error("Error completing audit", error);
        }
    };
    return (
        <Tooltip title={""}>
            <Button
                type={isVoided ? "primary" : "default"}
                size="large"
                danger
                block
                onClick={handleToggleVoidedAudit}>
                <CheckCircleOutlined />
                {isVoided
                    ? t("audit.detail.machinevoided")
                    : t("audit.detail.cantcompletemachine")}
            </Button>
        </Tooltip>
    );
}
