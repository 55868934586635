import React, { useEffect, useState } from "react";
import { firestore, functions } from "../../firebase";
import { endOfWeek, format, startOfWeek } from "date-fns";
import { collection, getDocs } from "firebase/firestore";
import { SensorType } from "general/enums";
import { RequestState } from "interfaces/request";

export default function useGPSWeeklyOverview(orgId: string, date: Date) {
    const [loading, setLoading] = useState(RequestState.INITIAL);
    const [loadingGps, setLoadingGps] = useState(RequestState.INITIAL);
    const [gpsData, setGpsData] = useState<any[]>([]);
    const [gps, setGps] = useState<any[]>([]);
    const gpsIds = gps.map(g => g.identifier);
    useEffect(() => {
        const getOrgs = async () => {
            try {
                setLoading(RequestState.PENDING);
                if (!orgId || !date || !gpsIds.length) {
                    return;
                }
                const startDate = format(
                    startOfWeek(date, { weekStartsOn: 1 }),
                    "yyyy-MM-dd",
                );
                const endDate = format(
                    endOfWeek(date, { weekStartsOn: 1 }),
                    "yyyy-MM-dd",
                );
                const functionRef = functions.httpsCallable(
                    "getGPSDashboardWeeklyOverview",
                );
                const response: any = await functionRef({
                    gpsIds,
                    orgId: "7brqXhTGkYzWwWEOoEzm",
                    startDate,
                    endDate,
                });

                console.log({ response });

                const errorCode = response?.data?.httpErrorCode?.status;
                if (errorCode) {
                    console.error({ errorCode });
                }

                setGpsData(
                    response?.data.map((d: any) => ({
                        gpsId: d.gpsId,
                        title: gps.find(g => g.identifier === d.gpsId)?.title,
                        startTrackPoint: JSON.parse(d.startTrackPoint),
                        endTrackPoint: JSON.parse(d.endTrackPoint),
                    })),
                );

                setLoading(RequestState.RESOLVED);
            } catch (error) {
                console.error(error);
                setLoading(RequestState.REJECTED);
            }
        };

        getOrgs();

        return () => {};
    }, [orgId, gpsIds?.length, date]);
    useEffect(() => {
        const getGpsSensors = async () => {
            try {
                setLoadingGps(RequestState.PENDING);
                if (!orgId || !date) {
                    return;
                }

                const snapshot = await firestore
                    .collection(`orgs/${orgId}/sensors`)
                    .where("type", "==", SensorType.GPS_SIMTRACKING)
                    .get();
                const docs = snapshot.docs.map(doc => doc.data());

                setGps(
                    docs.map((d: any) => ({ identifier: d.identifier, title: d.title })),
                );
                setLoadingGps(RequestState.RESOLVED);
            } catch (error) {
                console.error(error);
                setLoadingGps(RequestState.REJECTED);
            }
        };

        getGpsSensors();

        return () => {};
    }, [orgId]);

    console.log({ loading, loadingGps, gpsData });

    return {
        loading:
            loading === RequestState.INITIAL ||
            loading === RequestState.PENDING ||
            loadingGps === RequestState.INITIAL ||
            loadingGps === RequestState.PENDING,
        gpsData,
    };
}
