import { functions } from "../../../firebase";
import React, { useEffect, useState } from "react";

export default function useTrips({
    orgId,
    sensorId,
    date,
}: {
    orgId: string;
    sensorId: string;
    date: string;
}) {
    const [loading, setLoading] = useState(true);
    const [trips, setTrips] = useState([]);
    useEffect(() => {
        const getTrips = async () => {
            try {
                if (!loading) {
                    setLoading(true);
                }
                const functionRef = functions.httpsCallable("getGpsGateTrips");
                const response: any = await functionRef({
                    sensorId,
                    orgId,
                    date,
                });

                const errorCode = response?.data?.httpErrorCode?.status;
                if (errorCode) {
                    console.log({ errorCode });
                }
                setTrips(response?.data);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        if (orgId && sensorId && date) {
            getTrips();
        }

        return () => {};
    }, [orgId, sensorId, date]);

    return { trips, isTripsLoading: loading };
}

export const dummyData = [
    {
        gpsId: "13",
        trackInfoId: 655,
        isIdle: false,
        totalDistance: 80.40120761579037,
        startTrackPoint: {
            position: {
                altitude: 9,
                longitude: 9.7554633,
                latitude: 55.8841283,
            },
            velocity: {
                groundSpeed: 0.2777779996395111,
                heading: 0,
            },
            utc: "2024-09-02T04:05:39Z",
            valid: true,
        },
        endTrackPoint: {
            position: {
                altitude: 25,
                longitude: 9.754706874966669,
                latitude: 55.88411000000502,
            },
            velocity: {
                groundSpeed: 0,
                heading: 28.25795103253638,
            },
            utc: "2024-09-02T04:10:04Z",
            valid: true,
        },
        orgId: "7brqXhTGkYzWwWEOoEzm",
    },
    {
        gpsId: "13",
        trackInfoId: 655,
        isIdle: true,
        totalDistance: 0,
        startTrackPoint: {
            position: {
                altitude: 25,
                longitude: 9.754706874966669,
                latitude: 55.88411000000502,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T04:10:04Z",
            valid: true,
        },
        endTrackPoint: {
            position: {
                altitude: 25,
                longitude: 9.754706874966669,
                latitude: 55.88411000000502,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T04:58:22Z",
            valid: true,
        },
        orgId: "7brqXhTGkYzWwWEOoEzm",
    },
    {
        gpsId: "13",
        trackInfoId: 655,
        isIdle: false,
        totalDistance: 200.60650724143113,
        startTrackPoint: {
            position: {
                altitude: 25,
                longitude: 9.754706874966669,
                latitude: 55.88411000000502,
            },
            velocity: {
                groundSpeed: 0,
                heading: 24,
            },
            utc: "2024-09-02T04:58:22Z",
            valid: true,
        },
        endTrackPoint: {
            position: {
                altitude: 24,
                longitude: 9.7532748,
                latitude: 55.88428,
            },
            velocity: {
                groundSpeed: 0,
                heading: 181,
            },
            utc: "2024-09-02T05:01:34Z",
            valid: true,
        },
        orgId: "7brqXhTGkYzWwWEOoEzm",
    },
    {
        gpsId: "13",
        trackInfoId: 655,
        isIdle: true,
        totalDistance: 0,
        startTrackPoint: {
            position: {
                altitude: 24,
                longitude: 9.7532748,
                latitude: 55.88428,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T05:01:34Z",
            valid: true,
        },
        endTrackPoint: {
            position: {
                altitude: 24,
                longitude: 9.7532748,
                latitude: 55.88428,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T05:18:08Z",
            valid: true,
        },
        orgId: "7brqXhTGkYzWwWEOoEzm",
    },
    {
        gpsId: "13",
        trackInfoId: 655,
        isIdle: false,
        totalDistance: 2511.3169096206716,
        startTrackPoint: {
            position: {
                altitude: 24,
                longitude: 9.7532748,
                latitude: 55.88428,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T05:18:08Z",
            valid: true,
        },
        endTrackPoint: {
            position: {
                altitude: 6,
                longitude: 9.7533283,
                latitude: 55.8853366,
            },
            velocity: {
                groundSpeed: 0,
                heading: 15,
            },
            utc: "2024-09-02T05:52:04Z",
            valid: true,
        },
        orgId: "7brqXhTGkYzWwWEOoEzm",
    },
    {
        gpsId: "13",
        trackInfoId: 655,
        isIdle: true,
        totalDistance: 0,
        startTrackPoint: {
            position: {
                altitude: 6,
                longitude: 9.7533283,
                latitude: 55.8853366,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T05:52:04Z",
            valid: true,
        },
        endTrackPoint: {
            position: {
                altitude: 6,
                longitude: 9.7533283,
                latitude: 55.8853366,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T05:57:05Z",
            valid: true,
        },
        orgId: "7brqXhTGkYzWwWEOoEzm",
    },
    {
        gpsId: "13",
        trackInfoId: 655,
        isIdle: false,
        totalDistance: 220.2652056694252,
        startTrackPoint: {
            position: {
                altitude: 6,
                longitude: 9.7533283,
                latitude: 55.8853366,
            },
            velocity: {
                groundSpeed: 0,
                heading: 18,
            },
            utc: "2024-09-02T05:57:05Z",
            valid: true,
        },
        endTrackPoint: {
            position: {
                altitude: 19.583333333333332,
                longitude: 9.754315483333135,
                latitude: 55.88520731458335,
            },
            velocity: {
                groundSpeed: 0,
                heading: 204,
            },
            utc: "2024-09-02T06:09:32Z",
            valid: true,
        },
        orgId: "7brqXhTGkYzWwWEOoEzm",
    },
    {
        gpsId: "13",
        trackInfoId: 655,
        isIdle: true,
        totalDistance: 0,
        startTrackPoint: {
            position: {
                altitude: 19.583333333333332,
                longitude: 9.754315483333135,
                latitude: 55.88520731458335,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T06:09:32Z",
            valid: true,
        },
        endTrackPoint: {
            position: {
                altitude: 19.583333333333332,
                longitude: 9.754315483333135,
                latitude: 55.88520731458335,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T06:16:12Z",
            valid: true,
        },
        orgId: "7brqXhTGkYzWwWEOoEzm",
    },
    {
        gpsId: "13",
        trackInfoId: 655,
        isIdle: false,
        totalDistance: 728.7535477126469,
        startTrackPoint: {
            position: {
                altitude: 19.583333333333332,
                longitude: 9.754315483333135,
                latitude: 55.88520731458335,
            },
            velocity: {
                groundSpeed: 0,
                heading: 5,
            },
            utc: "2024-09-02T06:16:12Z",
            valid: true,
        },
        endTrackPoint: {
            position: {
                altitude: 26.4,
                longitude: 9.753839286665286,
                latitude: 55.88553152000011,
            },
            velocity: {
                groundSpeed: 0,
                heading: 174,
            },
            utc: "2024-09-02T06:33:24Z",
            valid: true,
        },
        orgId: "7brqXhTGkYzWwWEOoEzm",
    },
    {
        gpsId: "13",
        trackInfoId: 655,
        isIdle: true,
        totalDistance: 0,
        startTrackPoint: {
            position: {
                altitude: 26.4,
                longitude: 9.753839286665286,
                latitude: 55.88553152000011,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T06:33:24Z",
            valid: true,
        },
        endTrackPoint: {
            position: {
                altitude: 26.4,
                longitude: 9.753839286665286,
                latitude: 55.88553152000011,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T06:36:23Z",
            valid: true,
        },
        orgId: "7brqXhTGkYzWwWEOoEzm",
    },
    {
        gpsId: "13",
        trackInfoId: 655,
        isIdle: false,
        totalDistance: 474.83021825106937,
        startTrackPoint: {
            position: {
                altitude: 26.4,
                longitude: 9.753839286665286,
                latitude: 55.88553152000011,
            },
            velocity: {
                groundSpeed: 0,
                heading: 125,
            },
            utc: "2024-09-02T06:36:23Z",
            valid: true,
        },
        endTrackPoint: {
            position: {
                altitude: 27.964285714285715,
                longitude: 9.754507894125897,
                latitude: 55.88414925292578,
            },
            velocity: {
                groundSpeed: 0,
                heading: 151,
            },
            utc: "2024-09-02T06:57:17Z",
            valid: true,
        },
        orgId: "7brqXhTGkYzWwWEOoEzm",
    },
    {
        gpsId: "13",
        trackInfoId: 655,
        isIdle: true,
        totalDistance: 0,
        startTrackPoint: {
            position: {
                altitude: 27.964285714285715,
                longitude: 9.754507894125897,
                latitude: 55.88414925292578,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T06:57:17Z",
            valid: true,
        },
        endTrackPoint: {
            position: {
                altitude: 27.964285714285715,
                longitude: 9.754507894125897,
                latitude: 55.88414925292578,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T07:34:17Z",
            valid: true,
        },
        orgId: "7brqXhTGkYzWwWEOoEzm",
    },
    {
        gpsId: "13",
        trackInfoId: 655,
        isIdle: false,
        totalDistance: 448.031383488969,
        startTrackPoint: {
            position: {
                altitude: 27.964285714285715,
                longitude: 9.754507894125897,
                latitude: 55.88414925292578,
            },
            velocity: {
                groundSpeed: 0,
                heading: 65,
            },
            utc: "2024-09-02T07:34:17Z",
            valid: true,
        },
        endTrackPoint: {
            position: {
                altitude: 37.125,
                longitude: 9.755396692499371,
                latitude: 55.885451585000155,
            },
            velocity: {
                groundSpeed: 0,
                heading: 82,
            },
            utc: "2024-09-02T07:45:37Z",
            valid: true,
        },
        orgId: "7brqXhTGkYzWwWEOoEzm",
    },
    {
        gpsId: "13",
        trackInfoId: 655,
        isIdle: true,
        totalDistance: 0,
        startTrackPoint: {
            position: {
                altitude: 37.125,
                longitude: 9.755396692499371,
                latitude: 55.885451585000155,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T07:45:37Z",
            valid: true,
        },
        endTrackPoint: {
            position: {
                altitude: 37.125,
                longitude: 9.755396692499371,
                latitude: 55.885451585000155,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T07:51:53Z",
            valid: true,
        },
        orgId: "7brqXhTGkYzWwWEOoEzm",
    },
    {
        gpsId: "13",
        trackInfoId: 655,
        isIdle: false,
        totalDistance: 3223.2657994624224,
        startTrackPoint: {
            position: {
                altitude: 37.125,
                longitude: 9.755396692499371,
                latitude: 55.885451585000155,
            },
            velocity: {
                groundSpeed: 0,
                heading: 284,
            },
            utc: "2024-09-02T07:51:53Z",
            valid: true,
        },
        endTrackPoint: {
            position: {
                altitude: 19,
                longitude: 9.7528165,
                latitude: 55.8830066,
            },
            velocity: {
                groundSpeed: 0,
                heading: 6,
            },
            utc: "2024-09-02T08:30:00Z",
            valid: true,
        },
        orgId: "7brqXhTGkYzWwWEOoEzm",
    },
    {
        gpsId: "13",
        trackInfoId: 655,
        isIdle: true,
        totalDistance: 0,
        startTrackPoint: {
            position: {
                altitude: 19,
                longitude: 9.7528165,
                latitude: 55.8830066,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T08:30:00Z",
            valid: true,
        },
        endTrackPoint: {
            position: {
                altitude: 19,
                longitude: 9.7528165,
                latitude: 55.8830066,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T08:35:00Z",
            valid: true,
        },
        orgId: "7brqXhTGkYzWwWEOoEzm",
    },
    {
        gpsId: "13",
        trackInfoId: 655,
        isIdle: false,
        totalDistance: 54.2121059896563,
        startTrackPoint: {
            position: {
                altitude: 19,
                longitude: 9.7528165,
                latitude: 55.8830066,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T08:35:00Z",
            valid: true,
        },
        endTrackPoint: {
            position: {
                altitude: 0,
                longitude: 9.753682390909093,
                latitude: 55.88296027272727,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T08:40:01Z",
            valid: true,
        },
        orgId: "7brqXhTGkYzWwWEOoEzm",
    },
    {
        gpsId: "13",
        trackInfoId: 655,
        isIdle: true,
        totalDistance: 0,
        startTrackPoint: {
            position: {
                altitude: 0,
                longitude: 9.753682390909093,
                latitude: 55.88296027272727,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T08:40:01Z",
            valid: true,
        },
        endTrackPoint: {
            position: {
                altitude: 0,
                longitude: 9.753682390909093,
                latitude: 55.88296027272727,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T09:30:06Z",
            valid: true,
        },
        orgId: "7brqXhTGkYzWwWEOoEzm",
    },
    {
        gpsId: "13",
        trackInfoId: 655,
        isIdle: false,
        totalDistance: 957.8918591105268,
        startTrackPoint: {
            position: {
                altitude: 0,
                longitude: 9.753682390909093,
                latitude: 55.88296027272727,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T09:30:06Z",
            valid: true,
        },
        endTrackPoint: {
            position: {
                altitude: 19,
                longitude: 9.753435,
                latitude: 55.8844033,
            },
            velocity: {
                groundSpeed: 0,
                heading: 256,
            },
            utc: "2024-09-02T09:49:50Z",
            valid: true,
        },
        orgId: "7brqXhTGkYzWwWEOoEzm",
    },
    {
        gpsId: "13",
        trackInfoId: 655,
        isIdle: true,
        totalDistance: 0,
        startTrackPoint: {
            position: {
                altitude: 19,
                longitude: 9.753435,
                latitude: 55.8844033,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T09:49:50Z",
            valid: true,
        },
        endTrackPoint: {
            position: {
                altitude: 19,
                longitude: 9.753435,
                latitude: 55.8844033,
            },
            velocity: {
                groundSpeed: 0,
                heading: 0,
            },
            utc: "2024-09-02T10:49:57Z",
            valid: true,
        },
        orgId: "7brqXhTGkYzWwWEOoEzm",
    },
];
