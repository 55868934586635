import React, { useState } from "react";
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    InfoCircleOutlined,
} from "@ant-design/icons";
import { Badge, Button, Modal, Tooltip } from "antd";
import { StyleGuide } from "styles/StyleGuide";
import { useAppSelector } from "../../../hooks";
import AuditTaskCustomFieldsForm from "../AuditTaskCustomFieldsForm";

interface Props {
    orgId: string;
    auditId: string;
    taskId: string;
    disabled?: boolean;
}

export default function AuditTaskCustomFieldsAction({
    orgId,
    auditId,
    taskId,
    disabled,
}: Props) {
    const [open, setOpen] = useState(false);
    const task = useAppSelector(
        state => state.auditSchedules.currentAuditSchedule.tasks[taskId],
    );

    const noCustomFields = !task?.customFields || task?.customFields?.length < 1;
    const hasIncompleteRequiredCustomFields =
        task?.customFields &&
        task?.customFields?.some(
            cf =>
                cf.required &&
                (cf.value === null || cf.value === undefined || cf.value === ""),
        ); // value is required and not filled out

    const isAllFilledout =
        task?.customFields && task?.customFields?.every(cf => cf?.value);

    const showModal = () => setOpen(true);
    const handleCancel = () => setOpen(false);

    if (noCustomFields) return null;

    const getCustomFieldIcon = () => {
        if (isAllFilledout) {
            return {
                color: "green",
                icon: <CheckCircleOutlined style={{ color: "green" }} />,
                tooltip: "Alle felter er udfyldt",
            };
        }
        if (hasIncompleteRequiredCustomFields) {
            return {
                color: StyleGuide.palette.redish,
                icon: (
                    <ExclamationCircleOutlined
                        style={{ color: StyleGuide.palette.redish }}
                    />
                ),
                tooltip: "Obligatoriske felter mangler udfyldning",
            };
        }
        return {
            color: StyleGuide.palette.amber,
            icon: <InfoCircleOutlined style={{ color: StyleGuide.palette.amber }} />,
            tooltip: "Ikke alle felter er udfyldt",
        };
    };

    const { icon, color, tooltip } = getCustomFieldIcon();

    return (
        <>
            <Tooltip title={tooltip}>
                <Button
                    style={{ color }}
                    onClick={showModal}
                    disabled={disabled}
                    icon={
                        <Badge dot={!isAllFilledout} color={color}>
                            {icon}
                        </Badge>
                    }>
                    Registreringer
                </Button>
            </Tooltip>

            <Modal
                destroyOnClose
                title="Udfyld registreringer"
                open={open}
                footer={null}
                onCancel={handleCancel}>
                <div>
                    <div style={{ marginTop: StyleGuide.spacing.base }} />
                    <AuditTaskCustomFieldsForm
                        orgId={orgId}
                        auditScheduleId={auditId}
                        taskId={taskId}
                        onClose={handleCancel}
                    />
                </div>
            </Modal>
        </>
    );
}
