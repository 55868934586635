import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { useAppSelector } from "../../hooks";
import { useTranslation } from "react-i18next";
import { DBAuditSchedule } from "modules/auditSchedule/types";
import confirmModal from "general/confirmModal";
import { StyleGuide } from "styles/StyleGuide";
import { AuditCompleteState } from "@aks2tal/shared";

interface Props {
    updateAuditSchedule: (data: Partial<DBAuditSchedule>) => void;
}

export default function AuditToggleComplete(props: Props) {
    const { t } = useTranslation();
    const isCompleted = useAppSelector(
        state => state.auditSchedules.currentAuditSchedule?.isCompleted,
    );
    const isVoided = useAppSelector(
        state =>
            state.auditSchedules.currentAuditSchedule?.state ===
            AuditCompleteState.VOIDED,
    );
    const hasInCompleteTasks = useAppSelector(state =>
        Object.keys(state.auditSchedules.currentAuditSchedule.tasks).length < 1
            ? false
            : Object.entries(state.auditSchedules.currentAuditSchedule.tasks).some(
                  ([_, task]) => !task.isCompleted,
              ),
    );

    const hasTasksWithMissingCustomFields = useAppSelector(state =>
        Object.values(state.auditSchedules.currentAuditSchedule.tasks).some(task =>
            task?.customFields?.some(
                customField =>
                    customField.required &&
                    (customField.value === null ||
                        customField.value === undefined ||
                        customField.value === ""),
            ),
        ),
    );

    console.log({ hasTasksWithMissingCustomFields });

    const style = isCompleted
        ? {
              backgroundColor: StyleGuide.palette.successLight,
              color: StyleGuide.palette.successDark,
          }
        : hasTasksWithMissingCustomFields
        ? {
              backgroundColor: StyleGuide.palette.grey300,
              color: StyleGuide.palette.grey800,
          }
        : {};

    /**
     * TODO: should we check with them if tasks are not checked if that is on purpose?
     * If the is any tasks?
     */
    const handleToggleCompleteAudit = () => {
        if (isCompleted) {
            return confirmModal({
                title: t("audit.detail.confirmCancelCompletion"),
                onOk: () => {
                    props.updateAuditSchedule({
                        isCompleted: !isCompleted,
                    });
                },
            });
        }
        if (hasInCompleteTasks) {
            return confirmModal({
                title: t("audit.detail.confirmCompelteInCompleteTasks"),
                okType: "primary",
                onOk: () => {
                    props.updateAuditSchedule({
                        isCompleted: !isCompleted,
                        completeTime: new Date(),
                    });
                },
            });
        }
        return props.updateAuditSchedule({
            isCompleted: !isCompleted,
            completeTime: new Date(),
        });
    };
    if (isVoided) {
        return null;
    }
    return (
        <Tooltip
            title={
                hasTasksWithMissingCustomFields
                    ? "Der påkrævede registeringer der skal udfyldes før opgaven kan afsluttes"
                    : ""
            }>
            <Button
                size="large"
                type="primary"
                block
                style={style}
                disabled={hasTasksWithMissingCustomFields}
                onClick={handleToggleCompleteAudit}>
                <CheckCircleOutlined />
                {isCompleted ? t("audit.detail.completed") : t("audit.detail.complete")}
            </Button>
        </Tooltip>
    );
}
