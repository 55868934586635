import React, { useState } from "react";
import { Select, Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks";
import AdminOnly from "components/AdminOnly";
import { StyleGuide } from "styles/StyleGuide";
import { InfoCircleOutlined } from "@ant-design/icons";
import { AuditType } from "@aks2tal/shared";

const auditTemplateTypes = [
    { value: AuditType.MACHINE, label: "Maskine" },
    { value: AuditType.EQUIPMENT, label: "Udstyr" },
];

export default function AuditTemplateType({
    orgId,
    auditTemplateId,
    auditTemplateType,
    updateAuditTemplate,
}: {
    orgId: string;
    auditTemplateId: string;
    auditTemplateType?: AuditType | null;
    updateAuditTemplate: ({
        orgId,
        auditTemplateId,
        data: { auditTemplateType },
    }: {
        orgId: string;
        auditTemplateId: string;
        data: { auditTemplateType: AuditType | null };
    }) => Promise<void>;
}) {
    const groupId = useAppSelector(state => state.orgs.currentOrg?.groupId);
    const { t } = useTranslation();

    // const count = useAuditByAuditTemplateIdCount(orgId, auditTemplateId);

    const onSelectCategory = async (value: AuditType | undefined) => {
        try {
            if (!orgId || !auditTemplateId) {
                throw new Error("[auditTemplateType]: orgId or auditTemplateId is null");
            }
            await updateAuditTemplate({
                orgId,
                auditTemplateId,
                data: {
                    auditTemplateType: value ?? null,
                },
            });
            // if (count > 0 && value) {
            //     setvisible(true);
            // }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <AdminOnly>
            <div>
                <div
                    style={{
                        marginTop: 16,
                        borderBottom: `1px solid ${StyleGuide.palette.grey200}`,
                        marginBottom: 16,
                    }}
                />
                <Tooltip title="Valg af opgave type giver nogle andre muligheder registreringer, fx at maskinopgaver kan markeres som ikke at have været i drift">
                    <Typography.Text>
                        {t("Opgave type")} <InfoCircleOutlined />
                    </Typography.Text>
                </Tooltip>
                <div style={{ marginBottom: 4 }} />
                <Select
                    value={auditTemplateType}
                    style={{ width: "100%", minWidth: 200 }}
                    placeholder="Vælg type"
                    onChange={onSelectCategory}
                    allowClear
                    showSearch
                    optionFilterProp="label"
                    // loading={loading}
                    options={auditTemplateTypes.map(category => ({
                        value: category.value,
                        label: category.label,
                    }))}
                />
            </div>
        </AdminOnly>
    );
}
