import { Colors } from "Layout/Colors";
import { PresetStatusColorTypes } from "antd/es/_util/colors";
import { PresetColors } from "antd/es/theme/internal";
import { StyleGuide } from "styles/StyleGuide";

export function percentageToColor(perc: number, min: number, max: number) {
    var base = max - min;

    if (base == 0) {
        perc = 100;
    } else {
        perc = ((perc - min) / base) * 100;
    }
    var r,
        g,
        b = 0;
    if (perc < 50) {
        r = 255;
        g = Math.round(5.1 * perc);
    } else {
        g = 255;
        r = Math.round(510 - 5.1 * perc);
    }
    var h = r * 0x10000 + g * 0x100 + b * 0x1;
    return "#" + ("000000" + h.toString(16)).slice(-6);
}

//value from 0 to 1
export function getColor(value: number) {
    if (value > 1) value = 1;
    var hue = ((1 - value) * 120).toString(10);
    return ["hsl(", hue, ",100%,50%)"].join("");
}
export type TaskStatus =
    | "success"
    | "processing"
    | "error"
    | "default"
    | "warning"
    | "hardWarning"
    | "inprogress"
    | "voided";

export function getLightColorStatus(value: TaskStatus) {
    switch (value) {
        case "processing":
            return "#E3F2FD";
        case "inprogress":
            return "#A0E6E6";
        case "success":
            return "#E8F5E9";
        case "error":
            return "#FFEBEE";
        case "warning":
            return "#FFF3E0";
        case "hardWarning":
            return "#FDE9D7";
        case "voided":
            return StyleGuide.palette.grey300;
        default:
            return "#ECEFF1";
    }
}
export function getDarkColorStatus(value: TaskStatus) {
    switch (value) {
        case "processing":
            return "#90CAF9";
        case "inprogress":
            return "#13c2c2";
        case "success":
            return "#A5D6A7";
        case "error":
            return "#EF9A9A";
        case "warning":
            return "#FFCC80";
        case "hardWarning":
            return Colors.hardWarning;
        case "voided":
            return StyleGuide.palette.grey500;
        default:
            return "#B0BEC5";
    }
}
export function getTextColorStatus(value: TaskStatus) {
    switch (value) {
        case "processing":
            return StyleGuide.palette.info;
        case "inprogress":
            return "#0097A7";
        case "success":
            return "#4DB6AC";
        case "error":
            return StyleGuide.palette.redishDark;
        case "warning":
            return "#FFA726";
        case "hardWarning":
            return Colors.hardWarning;
        case "voided":
            return StyleGuide.palette.grey500;
        default:
            return StyleGuide.palette.infoDark;
    }
}
export function getBadgeColorStatus(value: TaskStatus) {
    switch (value) {
        case "processing":
            return "#1976D2";
        case "inprogress":
            return "#009688";
        case "success":
            return "#4CAF50";
        case "error":
            return "#F44336";
        case "warning":
            return "#FFC107";
        case "hardWarning":
            return "#FF5722";
        case "voided":
            return StyleGuide.palette.grey500;
        default:
            return "#9E9E9E";
    }
}
