import { ResponsiveHeatMap } from "@nivo/heatmap";
import { formatMinutesToHHmm } from "general/time";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const GPSHeatmap = ({ data }: any) => (
    <ResponsiveHeatMap
        data={data}
        margin={{ top: 40, right: 0, bottom: 60, left: 120 }}
        // valueFormat=">-.2s"
        label={label => `${formatMinutesToHHmm(label.data.y || 0)}`}
        axisTop={{
            tickSize: 10,
            tickPadding: 8,
            tickRotation: 0,
            legend: "",
            legendOffset: 0,
            truncateTickAt: 0,
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "",
            legendPosition: "middle",
            legendOffset: -72,
            truncateTickAt: 0,
        }}
        colors={{
            type: "sequential",
            scheme: "greens",
            // divergeAt: 0.5,
            minValue: 0,
        }}
        theme={{
            labels: {
                text: {
                    fontSize: 14, // Adjust font size for labels inside cells
                },
            },
            axis: {
                ticks: {
                    text: {
                        fontSize: 14, // Increase font size here
                    },
                },
            },
        }}
        emptyColor="#555555"
        // legends={[
        //     {
        //         anchor: "bottom",
        //         translateX: 0,
        //         translateY: 30,
        //         length: 400,
        //         thickness: 8,
        //         direction: "row",
        //         tickPosition: "after",
        //         tickSize: 3,
        //         tickSpacing: 4,
        //         tickOverlap: false,
        //         tickFormat: ">-.2s",
        //         title: "Value →",
        //         titleAlign: "start",
        //         titleOffset: 4,
        //     },
        // ]}
    />
);
