import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { useAppSelector } from "hooks";
import { AuditSchedule } from "modules/auditSchedule/types";
import { AuditTemplate, AuditTemplateTask } from "modules/auditTemplates/types";
// import { Change, diffJson } from "diff";

export default function useTemplateHasChanges({
    orgId,
    auditTemplateId,
}: {
    orgId: string;
    auditTemplateId: string;
}) {
    const [auditSchedule, setAuditSchedule] = useState<AuditSchedule | null>(null);
    const auditTemplate = useAppSelector(
        state => state.auditTemplates.currentAuditTemplate,
    );
    const auditTemplateTasks = useAppSelector(
        state => state.auditTemplates.currentAuditTemplateTasks,
    );

    console.log({ auditTemplateTasks, auditSchedule });

    const { hasChanges, changesByType /* details*/ } = diffAuditTemplate(
        auditSchedule,
        auditTemplate,
        auditTemplateTasks,
    );
    useEffect(() => {
        let unsubscribe: any = null;
        if (orgId && auditTemplateId) {
            unsubscribe = firebase
                .firestore()
                .collection(`orgs`)
                .doc(orgId)
                .collection(`auditSchedules`)
                .where("templateId", "==", auditTemplateId)
                .where("isCompleted", "==", false)
                .where("dueDate", ">", new Date())
                .orderBy("dueDate", "desc")
                .limit(1)
                .onSnapshot(snap => {
                    if (!snap.empty) {
                        const doc = snap.docs[0];
                        const data = doc.data() as AuditSchedule;
                        setAuditSchedule(data);
                    }
                });
        }
        return () => {
            console.log("unsubscribe");
            unsubscribe?.();
        };
    }, [orgId, auditTemplateId]);

    return { hasChanges, changesByType /* details*/ };
}

// export type ChangeDiff = {
//     title: Change[] | null;
//     tasks: Change[] | null;
//     note: Change[] | null;
//     guide: Change[] | null;
//     category: Change[] | null;
// };

type ChangeKeys = {
    title: { hasChanges: boolean; before?: string | null; after?: string | null };
    tasks: { hasChanges: boolean; before?: string | null; after?: string | null };
    note: { hasChanges: boolean; before?: string | null; after?: string | null };
    guide: { hasChanges: boolean; before?: string | null; after?: string | null };
    category: { hasChanges: boolean; before?: string | null; after?: string | null };
    type: { hasChanges: boolean; before?: string | null; after?: string | null };
};

function diffAuditTemplate(
    audit: AuditSchedule | null,
    template: AuditTemplate,
    tasks: AuditTemplateTask[],
): {
    hasChanges: boolean;
    changesByType: ChangeKeys;
    // details: ChangeDiff;
} {
    if (!audit) {
        return {
            hasChanges: false,
            changesByType: {
                title: { hasChanges: false, before: null, after: null },
                tasks: { hasChanges: false, before: null, after: null },
                note: { hasChanges: false, before: null, after: null },
                guide: { hasChanges: false, before: null, after: null },
                category: { hasChanges: false, before: null, after: null },
                type: { hasChanges: false, before: null, after: null },
            },
            // details: {
            //     title: null,
            //     tasks: null,
            //     note: null,
            //     guide: null,
            //     category: null,
            // },
        };
    }
    const hasTitleChanged = audit.templateTitle !== template.title;
    const hasTasksChanged = diffAuditTasks(tasks, audit.tasks);
    // treat empty string and undefined and null as the same
    const hasNoteChanged = (audit.templateNote ?? "") !== (template.note ?? "");
    const hasGuideChanged = audit.guide?.path !== template.guide?.path;
    const hasCategoryChanged =
        (audit.auditTemplateCategory || null) !==
        (template.auditTemplateCategory || null);
    const hasTypeChanged = (audit.type || null) !== (template.auditTemplateType || null);
    // console.log({ hasTitleChanged, hasTasksChanged, hasNoteChanged, hasGuideChanged });
    // if any is true return true

    const before = {
        title: audit.templateTitle,
        tasks: audit.tasks,
        note: audit.templateNote,
        guide: audit.guide,
        category: audit.auditTemplateCategory,
        type: audit.type,
    };
    const after = {
        title: template.title,
        tasks,
        note: template.note,
        guide: template.guide,
        category: template.auditTemplateCategory,
        type: template.auditTemplateType,
    };

    // const diff: Change[] =
    // console.log({ diff });
    console.log({
        hasTitleChanged,
        hasTasksChanged,
        hasNoteChanged,
        hasGuideChanged,
        hasCategoryChanged,
        hasTypeChanged,
    });

    return {
        hasChanges:
            hasTitleChanged ||
            hasTasksChanged ||
            hasNoteChanged ||
            hasGuideChanged ||
            hasCategoryChanged ||
            hasTypeChanged,
        changesByType: {
            title: {
                hasChanges: hasTitleChanged,
                before: before.title,
                after: after.title,
            },
            tasks: {
                hasChanges: hasTasksChanged,
                before: Object.entries(before.tasks)
                    // @ts-ignore
                    .sort((a, b) => a[1]?.order - b[1]?.order)
                    .map(([_, task]) => task.description)
                    .join("\n"),
                after: after.tasks
                    // @ts-ignore
                    .sort((a, b) => a[1]?.order - b[1]?.order)
                    .map(task => task.description)
                    .join("\n"),
            },
            note: {
                hasChanges: hasNoteChanged,
                before: before.note,
                after: after.note,
            },
            guide: {
                hasChanges: hasGuideChanged,
                before: before.guide?.path,
                after: after.guide?.path,
            },
            category: {
                hasChanges: hasCategoryChanged,
                before: before.category,
                after: after.category,
            },
            type: {
                hasChanges: hasTypeChanged,
                before: before.type,
                after: after.type,
            },
        },
        // details: {
        //     title: hasTitleChanged ? diffJson(audit.templateTitle, template.title) : null,
        //     // @ts-ignore
        //     tasks: hasTasksChanged
        //         ? diffJson(
        //               Object.values(audit.tasks).map(t => t.description),
        //               tasks.map(t => t.description),
        //           )
        //         : null,

        //     // @ts-ignore
        //     note: hasNoteChanged ? diffJson(audit.templateNote, template.note) : null,
        //     guide: hasGuideChanged
        //         ? // @ts-ignore
        //           diffJson(audit.guide?.fileName, template.guide?.fileName)
        //         : null,
        //     category: hasCategoryChanged
        //         ? diffJson(
        //               audit.auditTemplateCategory || "",
        //               template.auditTemplateCategory || "",
        //           )
        //         : null,
        // },
    };
}

/**
 * Schema to check for changes in audit tasks
 * description
 * order
 * customFields
 */

function diffAuditTasks(
    templateTasks: AuditTemplateTask[],
    auditTaskMap: AuditSchedule["tasks"],
) {
    // id already included
    const audiTasksSchema = Object.values(auditTaskMap)
        // @ts-ignore
        .sort((a, b) => a?.order - b?.order)
        .map(task => ({
            id: task.id,
            description: task.description,
            order: task.order,
            customFields: task.customFields || [], // empty array if undefined
        }));

    const templateTasksSchema = templateTasks
        // @ts-ignore
        .sort((a, b) => a?.order - b?.order)
        .map(task => ({
            id: task.id,
            description: task.description,
            order: task.order,
            customFields: task.customFields || [], // empty array if undefined
        }));
    console.log({ templateTasksSchema, audiTasksSchema });

    // console.log({ templateTasksSchema, audiTasksSchema });
    // compare tasks both ways
    return JSON.stringify(templateTasksSchema) !== JSON.stringify(audiTasksSchema);
}
