import React, { useState } from "react";
import {
    startOfWeek,
    add,
    format,
    getYear,
    getWeek,
    parseISO,
    differenceInMinutes,
    addDays,
    subWeeks,
    isFuture,
} from "date-fns";
import { Button, Card, Typography, Space, Tooltip, Spin } from "antd";
import { CheckCircleOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Colors } from "../../Layout/Colors";
import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";
import { GPSHeatmap } from "./GPSgraph";
import useGPSWeeklyOverview from "./useGPSWeeklyOverview";
import { da } from "date-fns/locale";
import { addWeeks } from "date-fns";

const GPSWeeklyOverview = () => {
    const { t } = useTranslation();
    const { orgId } = useParams<{ orgId: string }>();

    // Initialize with current date
    const [currentDate, setCurrentDate] = useState(new Date());
    const { loading, gpsData } = useGPSWeeklyOverview(orgId, currentDate);

    const year = getYear(currentDate);
    const weekNumber = getWeek(currentDate, { weekStartsOn: 1 });

    const navigateWeeks = (amount: number) => {
        setCurrentDate(add(currentDate, { weeks: amount }));
    };

    const data = transformGpsData(gpsData, currentDate);

    return (
        <Card>
            <div
                style={{
                    marginBottom: 16,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}>
                <Space
                    style={{
                        display: "flex",
                        paddingLeft: 0,
                        paddingRight: 16,
                        paddingTop: 0,
                        paddingBottom: 0,
                        fontWeight: "bold",
                        fontSize: 16,
                    }}>
                    <CheckCircleOutlined
                        style={{ display: "flex", fontSize: 36, color: Colors.done }}
                    />
                    {/* <Typography.Title level={5} style={{margin:0}}> */}
                    {t("dashboard.gps.title")}
                    {/* </Typography.Title> */}
                </Space>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Button
                        shape="circle"
                        size="large"
                        icon={<LeftOutlined />}
                        onClick={() => navigateWeeks(-1)}
                    />

                    <div
                        style={{
                            textAlign: "center",
                            marginLeft: 8,
                            marginRight: 8,
                            display: "flex",
                            flexDirection: "column",
                            width: 80,
                        }}>
                        <Typography.Text strong style={{ fontSize: 16 }}>
                            Uge {weekNumber}
                        </Typography.Text>
                        <Typography.Text style={{ lineHeight: 1 }} type="secondary">
                            ({year})
                        </Typography.Text>
                    </div>
                    <Button
                        shape="circle"
                        size="large"
                        icon={<RightOutlined />}
                        disabled={isFuture(addWeeks(currentDate, 1))}
                        onClick={() => navigateWeeks(1)}
                    />
                </div>
            </div>
            <Spin spinning={loading}>
                <div style={{ width: "100%", height: 300 }}>
                    <GPSHeatmap data={data} />
                </div>
            </Spin>
        </Card>
    );
};

export default GPSWeeklyOverview;

const dataaaaaa = [
    {
        id: "Fejemaskine 1",
        data: [
            {
                x: "Mandag 18. nov",
                y: 120,
            },
            {
                x: "Tirsdag 19. nov",
                y: 120,
            },
            {
                x: "Onsdag 20. nov",
                y: 240,
            },
            {
                x: "Torsdag 21. nov",
                y: 360,
            },
            {
                x: "Fredag 22. nov",
                y: 0,
            },
            {
                x: "Lørdag 23. nov",
                y: 0,
            },
            {
                x: "Søndag 24. nov",
                y: 0,
            },
        ],
    },
    {
        id: "Fejemaskine 2",
        data: [
            {
                x: "Mandag 18. nov",
                y: 180,
            },
            {
                x: "Tirsdag 19. nov",
                y: 60,
            },
            {
                x: "Onsdag 20. nov",
                y: 0,
            },
            {
                x: "Torsdag 21. nov",
                y: 0,
            },
            {
                x: "Fredag 22. nov",
                y: 0,
            },
            {
                x: "Lørdag 23. nov",
                y: 0,
            },
            {
                x: "Søndag 24. nov",
                y: 0,
            },
        ],
    },
    {
        id: "Vaskemaskine 1",
        data: [
            {
                x: "Mandag 18. nov",
                y: 300,
            },
            {
                x: "Tirsdag 19. nov",
                y: 360,
            },
            {
                x: "Onsdag 20. nov",
                y: 420,
            },
            {
                x: "Torsdag 21. nov",
                y: 480,
            },
            {
                x: "Fredag 22. nov",
                y: 0,
            },
            {
                x: "Lørdag 23. nov",
                y: 0,
            },
            {
                x: "Søndag 24. nov",
                y: 0,
            },
        ],
    },
];

// const dataa = [
//     {
//         gpsId: "11",
//         startTrackPoint: {
//             position: {
//                 altitude: 56,
//                 latitude: 55.8833016,
//                 longitude: 9.7556083,
//             },
//             utc: "2024-11-18T07:05:46.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 56,
//                 latitude: 55.8833016,
//                 longitude: 9.7556083,
//             },
//             utc: "2024-11-18T07:10:47.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "11",
//         startTrackPoint: {
//             position: {
//                 altitude: 35,
//                 latitude: 55.88407,
//                 longitude: 9.7555599,
//             },
//             utc: "2024-11-18T06:30:08.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 35,
//                 latitude: 55.88407,
//                 longitude: 9.7555599,
//             },
//             utc: "2024-11-18T06:35:08.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "11",
//         startTrackPoint: {
//             position: {
//                 altitude: 31.419642857142858,
//                 latitude: 55.88435129899891,
//                 longitude: 9.753547409966073,
//             },
//             utc: "2024-11-18T07:16:08.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 31.419642857142858,
//                 latitude: 55.88435129899891,
//                 longitude: 9.753547409966073,
//             },
//             utc: "2024-11-18T11:39:16.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 38.833333333333336,
//                 latitude: 55.88425815833333,
//                 longitude: 9.752920416666667,
//             },
//             utc: "2024-11-18T04:40:16.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 38.833333333333336,
//                 latitude: 55.88425815833333,
//                 longitude: 9.752920416666667,
//             },
//             utc: "2024-11-18T06:41:16.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 35.5,
//                 latitude: 55.8829158,
//                 longitude: 9.7554133,
//             },
//             utc: "2024-11-18T10:00:20.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 35.5,
//                 latitude: 55.8829158,
//                 longitude: 9.7554133,
//             },
//             utc: "2024-11-18T10:12:26.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 23,
//                 latitude: 55.88425,
//                 longitude: 9.753155,
//             },
//             utc: "2024-11-18T08:42:21.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 23,
//                 latitude: 55.88425,
//                 longitude: 9.753155,
//             },
//             utc: "2024-11-18T09:41:12.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 22,
//                 latitude: 55.8841083,
//                 longitude: 9.75644,
//             },
//             utc: "2024-11-18T04:31:20.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 22,
//                 latitude: 55.8841083,
//                 longitude: 9.75644,
//             },
//             utc: "2024-11-18T04:37:47.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 8,
//                 latitude: 55.88452773333354,
//                 longitude: 9.75290829999513,
//             },
//             utc: "2024-11-18T04:02:45.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 8,
//                 latitude: 55.88452773333354,
//                 longitude: 9.75290829999513,
//             },
//             utc: "2024-11-18T04:12:52.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 45.5,
//                 latitude: 55.8842416,
//                 longitude: 9.7532116,
//             },
//             utc: "2024-11-18T10:23:28.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 45.5,
//                 latitude: 55.8842416,
//                 longitude: 9.7532116,
//             },
//             utc: "2024-11-18T12:23:41.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "11",
//         startTrackPoint: {
//             position: {
//                 altitude: 32.8,
//                 latitude: 55.884740970000664,
//                 longitude: 9.752786724996707,
//             },
//             utc: "2024-11-19T09:36:10.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 32.8,
//                 latitude: 55.884740970000664,
//                 longitude: 9.752786724996707,
//             },
//             utc: "2024-11-19T09:43:33.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "11",
//         startTrackPoint: {
//             position: {
//                 altitude: 45,
//                 latitude: 55.8829633,
//                 longitude: 9.7566037,
//             },
//             utc: "2024-11-19T08:54:08.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 45,
//                 latitude: 55.8829633,
//                 longitude: 9.7566037,
//             },
//             utc: "2024-11-19T08:56:16.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "11",
//         startTrackPoint: {
//             position: {
//                 altitude: 1,
//                 latitude: 55.88449,
//                 longitude: 9.7528933,
//             },
//             utc: "2024-11-19T10:35:58.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 1,
//                 latitude: 55.88449,
//                 longitude: 9.7528933,
//             },
//             utc: "2024-11-19T10:50:26.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "11",
//         startTrackPoint: {
//             position: {
//                 altitude: 24.182692307692307,
//                 latitude: 55.88454405605769,
//                 longitude: 9.753120512884614,
//             },
//             utc: "2024-11-19T08:33:36.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 24.182692307692307,
//                 latitude: 55.88454405605769,
//                 longitude: 9.753120512884614,
//             },
//             utc: "2024-11-19T08:36:35.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "11",
//         startTrackPoint: {
//             position: {
//                 altitude: 29.4,
//                 latitude: 55.88273863000005,
//                 longitude: 9.754631779999134,
//             },
//             utc: "2024-11-19T10:15:34.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 29.4,
//                 latitude: 55.88273863000005,
//                 longitude: 9.754631779999134,
//             },
//             utc: "2024-11-19T10:17:38.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "11",
//         startTrackPoint: {
//             position: {
//                 altitude: 40.5,
//                 latitude: 55.883150799999996,
//                 longitude: 9.755524099999999,
//             },
//             utc: "2024-11-19T09:05:18.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 40.5,
//                 latitude: 55.883150799999996,
//                 longitude: 9.755524099999999,
//             },
//             utc: "2024-11-19T09:15:19.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "11",
//         startTrackPoint: {
//             position: {
//                 altitude: 5,
//                 latitude: 55.8843283,
//                 longitude: 9.7529333,
//             },
//             utc: "2024-11-19T10:53:54.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 5,
//                 latitude: 55.8843283,
//                 longitude: 9.7529333,
//             },
//             utc: "2024-11-19T11:54:01.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 40,
//                 latitude: 55.88405495,
//                 longitude: 9.75334075,
//             },
//             utc: "2024-11-19T07:22:59.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 40,
//                 latitude: 55.88405495,
//                 longitude: 9.75334075,
//             },
//             utc: "2024-11-19T07:27:54.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 22.5,
//                 latitude: 55.88411495,
//                 longitude: 9.7548099,
//             },
//             utc: "2024-11-19T03:45:10.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 22.5,
//                 latitude: 55.88411495,
//                 longitude: 9.7548099,
//             },
//             utc: "2024-11-19T03:56:02.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 39.25,
//                 latitude: 55.88422799166666,
//                 longitude: 9.75670035,
//             },
//             utc: "2024-11-19T09:58:01.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 39.25,
//                 latitude: 55.88422799166666,
//                 longitude: 9.75670035,
//             },
//             utc: "2024-11-19T10:09:03.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 33,
//                 latitude: 55.8841,
//                 longitude: 9.7532533,
//             },
//             utc: "2024-11-19T04:29:33.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 33,
//                 latitude: 55.8841,
//                 longitude: 9.7532533,
//             },
//             utc: "2024-11-19T04:48:51.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 36.6,
//                 latitude: 55.88447806666667,
//                 longitude: 9.753082173333333,
//             },
//             utc: "2024-11-19T05:35:08.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 36.6,
//                 latitude: 55.88447806666667,
//                 longitude: 9.753082173333333,
//             },
//             utc: "2024-11-19T06:17:48.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 24,
//                 latitude: 55.88289,
//                 longitude: 9.7558499,
//             },
//             utc: "2024-11-19T06:31:42.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 24,
//                 latitude: 55.88289,
//                 longitude: 9.7558499,
//             },
//             utc: "2024-11-19T06:36:43.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 13,
//                 latitude: 55.8830216,
//                 longitude: 9.7556833,
//             },
//             utc: "2024-11-19T06:49:29.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 13,
//                 latitude: 55.8830216,
//                 longitude: 9.7556833,
//             },
//             utc: "2024-11-19T06:54:30.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 22,
//                 latitude: 55.883187254167055,
//                 longitude: 9.75446012917063,
//             },
//             utc: "2024-11-19T06:59:55.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 22,
//                 latitude: 55.883187254167055,
//                 longitude: 9.75446012917063,
//             },
//             utc: "2024-11-19T07:07:53.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 44,
//                 latitude: 55.8843216,
//                 longitude: 9.75317,
//             },
//             utc: "2024-11-19T07:12:37.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 44,
//                 latitude: 55.8843216,
//                 longitude: 9.75317,
//             },
//             utc: "2024-11-19T07:21:03.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 43.5,
//                 latitude: 55.8839580625,
//                 longitude: 9.755654275000001,
//             },
//             utc: "2024-11-19T04:02:02.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 43.5,
//                 latitude: 55.8839580625,
//                 longitude: 9.755654275000001,
//             },
//             utc: "2024-11-19T04:20:52.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 29.416666666666668,
//                 latitude: 55.88427815833333,
//                 longitude: 9.753341991666668,
//             },
//             utc: "2024-11-19T10:19:10.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 29.416666666666668,
//                 latitude: 55.88427815833333,
//                 longitude: 9.753341991666668,
//             },
//             utc: "2024-11-19T12:20:24.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 32,
//                 latitude: 55.885125,
//                 longitude: 9.7550183,
//             },
//             utc: "2024-11-19T09:30:55.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 32,
//                 latitude: 55.885125,
//                 longitude: 9.7550183,
//             },
//             utc: "2024-11-19T09:48:51.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 38.5,
//                 latitude: 55.88435553333334,
//                 longitude: 9.7531644,
//             },
//             utc: "2024-11-19T07:57:08.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 38.5,
//                 latitude: 55.88435553333334,
//                 longitude: 9.7531644,
//             },
//             utc: "2024-11-19T09:10:23.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "11",
//         startTrackPoint: {
//             position: {
//                 altitude: 38,
//                 latitude: 55.8855683,
//                 longitude: 9.7541765,
//             },
//             utc: "2024-11-20T10:18:41.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 38,
//                 latitude: 55.8855683,
//                 longitude: 9.7541765,
//             },
//             utc: "2024-11-20T10:23:42.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "11",
//         startTrackPoint: {
//             position: {
//                 altitude: 37.333333333333336,
//                 latitude: 55.88445914333959,
//                 longitude: 9.752645540028125,
//             },
//             utc: "2024-11-20T09:43:37.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 37.333333333333336,
//                 latitude: 55.88445914333959,
//                 longitude: 9.752645540028125,
//             },
//             utc: "2024-11-20T09:46:14.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "11",
//         startTrackPoint: {
//             position: {
//                 altitude: 25.125,
//                 latitude: 55.884466862500005,
//                 longitude: 9.753059525000001,
//             },
//             utc: "2024-11-20T10:49:35.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 25.125,
//                 latitude: 55.884466862500005,
//                 longitude: 9.753059525000001,
//             },
//             utc: "2024-11-20T11:52:53.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "11",
//         startTrackPoint: {
//             position: {
//                 altitude: 21.2,
//                 latitude: 55.885406690001545,
//                 longitude: 9.754082405005255,
//             },
//             utc: "2024-11-20T10:26:41.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 21.2,
//                 latitude: 55.885406690001545,
//                 longitude: 9.754082405005255,
//             },
//             utc: "2024-11-20T10:34:03.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "11",
//         startTrackPoint: {
//             position: {
//                 altitude: 15.75,
//                 latitude: 55.885304125000005,
//                 longitude: 9.755344124999999,
//             },
//             utc: "2024-11-20T10:07:18.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 15.75,
//                 latitude: 55.885304125000005,
//                 longitude: 9.755344124999999,
//             },
//             utc: "2024-11-20T10:09:55.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 40,
//                 latitude: 55.883965,
//                 longitude: 9.7552565,
//             },
//             utc: "2024-11-20T04:15:33.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 40,
//                 latitude: 55.883965,
//                 longitude: 9.7552565,
//             },
//             utc: "2024-11-20T04:33:02.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 32.22222222222222,
//                 latitude: 55.88437665,
//                 longitude: 9.753252166666668,
//             },
//             utc: "2024-11-20T09:35:24.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 32.22222222222222,
//                 latitude: 55.88437665,
//                 longitude: 9.753252166666668,
//             },
//             utc: "2024-11-20T11:44:44.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 16,
//                 latitude: 55.8841099,
//                 longitude: 9.7532316,
//             },
//             utc: "2024-11-20T04:37:28.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 16,
//                 latitude: 55.8841099,
//                 longitude: 9.7532316,
//             },
//             utc: "2024-11-20T04:54:08.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 33.8,
//                 latitude: 55.88426355,
//                 longitude: 9.753491050000001,
//             },
//             utc: "2024-11-20T05:13:56.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 33.8,
//                 latitude: 55.88426355,
//                 longitude: 9.753491050000001,
//             },
//             utc: "2024-11-20T07:42:42.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 19,
//                 latitude: 55.8843649,
//                 longitude: 9.7533516,
//             },
//             utc: "2024-11-20T07:54:32.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 19,
//                 latitude: 55.8843649,
//                 longitude: 9.7533516,
//             },
//             utc: "2024-11-20T08:32:15.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 39.0625,
//                 latitude: 55.88299538282041,
//                 longitude: 9.75651239376375,
//             },
//             utc: "2024-11-20T08:56:34.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 39.0625,
//                 latitude: 55.88299538282041,
//                 longitude: 9.75651239376375,
//             },
//             utc: "2024-11-20T09:17:26.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "11",
//         startTrackPoint: {
//             position: {
//                 altitude: 67,
//                 latitude: 55.8844883,
//                 longitude: 9.752315,
//             },
//             utc: "2024-11-21T10:55:42.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 67,
//                 latitude: 55.8844883,
//                 longitude: 9.752315,
//             },
//             utc: "2024-11-21T11:55:48.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "11",
//         startTrackPoint: {
//             position: {
//                 altitude: 20.136363636363637,
//                 latitude: 55.884514486363635,
//                 longitude: 9.75289785,
//             },
//             utc: "2024-11-21T10:28:17.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 20.136363636363637,
//                 latitude: 55.884514486363635,
//                 longitude: 9.75289785,
//             },
//             utc: "2024-11-21T10:31:28.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 37.58333333333333,
//                 latitude: 55.88402561319452,
//                 longitude: 9.755746925695169,
//             },
//             utc: "2024-11-21T03:49:13.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 37.58333333333333,
//                 latitude: 55.88402561319452,
//                 longitude: 9.755746925695169,
//             },
//             utc: "2024-11-21T04:33:42.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 59.5,
//                 latitude: 55.88401040000001,
//                 longitude: 9.75499825,
//             },
//             utc: "2024-11-21T03:41:12.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 59.5,
//                 latitude: 55.88401040000001,
//                 longitude: 9.75499825,
//             },
//             utc: "2024-11-21T03:43:41.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 51,
//                 latitude: 55.8844033,
//                 longitude: 9.7529899,
//             },
//             utc: "2024-11-21T04:38:20.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 51,
//                 latitude: 55.8844033,
//                 longitude: 9.7529899,
//             },
//             utc: "2024-11-21T04:52:07.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 33,
//                 latitude: 55.882825,
//                 longitude: 9.7561966,
//             },
//             utc: "2024-11-21T09:26:53.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 33,
//                 latitude: 55.882825,
//                 longitude: 9.7561966,
//             },
//             utc: "2024-11-21T09:33:21.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 27,
//                 latitude: 55.8854016,
//                 longitude: 9.7534316,
//             },
//             utc: "2024-11-21T06:57:44.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 27,
//                 latitude: 55.8854016,
//                 longitude: 9.7534316,
//             },
//             utc: "2024-11-21T07:03:36.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 31.75,
//                 latitude: 55.884179062520204,
//                 longitude: 9.75420091873484,
//             },
//             utc: "2024-11-21T09:42:10.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 31.75,
//                 latitude: 55.884179062520204,
//                 longitude: 9.75420091873484,
//             },
//             utc: "2024-11-21T09:49:18.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 29.166666666666668,
//                 latitude: 55.8831670833356,
//                 longitude: 9.75302191875384,
//             },
//             utc: "2024-11-21T08:43:25.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 29.166666666666668,
//                 latitude: 55.8831670833356,
//                 longitude: 9.75302191875384,
//             },
//             utc: "2024-11-21T08:55:05.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 27,
//                 latitude: 55.88424,
//                 longitude: 9.7532766,
//             },
//             utc: "2024-11-21T09:51:27.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 27,
//                 latitude: 55.88424,
//                 longitude: 9.7532766,
//             },
//             utc: "2024-11-21T10:51:34.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 39,
//                 latitude: 55.8844566,
//                 longitude: 9.7530466,
//             },
//             utc: "2024-11-21T07:32:11.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 39,
//                 latitude: 55.8844566,
//                 longitude: 9.7530466,
//             },
//             utc: "2024-11-21T07:37:57.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 31,
//                 latitude: 55.884085,
//                 longitude: 9.7532032,
//             },
//             utc: "2024-11-21T07:54:40.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 31,
//                 latitude: 55.884085,
//                 longitude: 9.7532032,
//             },
//             utc: "2024-11-21T08:31:55.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 22.25,
//                 latitude: 55.88413670555585,
//                 longitude: 9.75326230000156,
//             },
//             utc: "2024-11-21T05:03:39.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 22.25,
//                 latitude: 55.88413670555585,
//                 longitude: 9.75326230000156,
//             },
//             utc: "2024-11-21T06:19:59.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 21,
//                 latitude: 55.8853333,
//                 longitude: 9.7549233,
//             },
//             utc: "2024-11-22T06:42:06.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 21,
//                 latitude: 55.8853333,
//                 longitude: 9.7549233,
//             },
//             utc: "2024-11-22T06:47:06.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 20,
//                 latitude: 55.885335,
//                 longitude: 9.7535933,
//             },
//             utc: "2024-11-22T07:01:13.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 20,
//                 latitude: 55.885335,
//                 longitude: 9.7535933,
//             },
//             utc: "2024-11-22T07:06:14.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 29,
//                 latitude: 55.8842966,
//                 longitude: 9.7550766,
//             },
//             utc: "2024-11-22T10:07:45.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 29,
//                 latitude: 55.8842966,
//                 longitude: 9.7550766,
//             },
//             utc: "2024-11-22T10:34:36.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 29,
//                 latitude: 55.88547245,
//                 longitude: 9.754075,
//             },
//             utc: "2024-11-22T06:23:14.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 29,
//                 latitude: 55.88547245,
//                 longitude: 9.754075,
//             },
//             utc: "2024-11-22T06:33:15.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 26,
//                 latitude: 55.8844583,
//                 longitude: 9.7531566,
//             },
//             utc: "2024-11-22T07:17:45.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 26,
//                 latitude: 55.8844583,
//                 longitude: 9.7531566,
//             },
//             utc: "2024-11-22T07:26:31.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 34,
//                 latitude: 55.88408,
//                 longitude: 9.7549433,
//             },
//             utc: "2024-11-22T07:30:06.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 34,
//                 latitude: 55.88408,
//                 longitude: 9.7549433,
//             },
//             utc: "2024-11-22T07:40:16.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 27.5,
//                 latitude: 55.8840966,
//                 longitude: 9.7532433,
//             },
//             utc: "2024-11-22T07:53:00.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 27.5,
//                 latitude: 55.8840966,
//                 longitude: 9.7532433,
//             },
//             utc: "2024-11-22T09:52:26.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 38,
//                 latitude: 55.8842816,
//                 longitude: 9.7532933,
//             },
//             utc: "2024-11-22T10:42:44.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 38,
//                 latitude: 55.8842816,
//                 longitude: 9.7532933,
//             },
//             utc: "2024-11-22T11:42:51.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 27.5,
//                 latitude: 55.8843183,
//                 longitude: 9.75359,
//             },
//             utc: "2024-11-22T04:07:04.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 27.5,
//                 latitude: 55.8843183,
//                 longitude: 9.75359,
//             },
//             utc: "2024-11-22T05:36:03.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 28,
//                 latitude: 55.8841216,
//                 longitude: 9.75328,
//             },
//             utc: "2024-11-22T05:59:29.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 28,
//                 latitude: 55.8841216,
//                 longitude: 9.75328,
//             },
//             utc: "2024-11-22T06:18:27.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "10",
//         startTrackPoint: {
//             position: {
//                 altitude: 39.4,
//                 latitude: 55.882895639999994,
//                 longitude: 9.753390620000001,
//             },
//             utc: "2024-11-22T03:47:42.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 39.4,
//                 latitude: 55.882895639999994,
//                 longitude: 9.753390620000001,
//             },
//             utc: "2024-11-22T03:51:25.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "11",
//         startTrackPoint: {
//             position: {
//                 altitude: 40.8,
//                 latitude: 55.8844430500013,
//                 longitude: 9.754826955002061,
//             },
//             utc: "2024-11-22T10:20:23.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 40.8,
//                 latitude: 55.8844430500013,
//                 longitude: 9.754826955002061,
//             },
//             utc: "2024-11-22T10:22:41.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "11",
//         startTrackPoint: {
//             position: {
//                 altitude: 34.8,
//                 latitude: 55.882762630002574,
//                 longitude: 9.753564209989682,
//             },
//             utc: "2024-11-22T10:40:17.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 34.8,
//                 latitude: 55.882762630002574,
//                 longitude: 9.753564209989682,
//             },
//             utc: "2024-11-22T10:44:09.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
//     {
//         gpsId: "11",
//         startTrackPoint: {
//             position: {
//                 altitude: 32.38888888888889,
//                 latitude: 55.88446993333333,
//                 longitude: 9.752942433333333,
//             },
//             utc: "2024-11-22T11:00:39.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//         endTrackPoint: {
//             position: {
//                 altitude: 32.38888888888889,
//                 latitude: 55.88446993333333,
//                 longitude: 9.752942433333333,
//             },
//             utc: "2024-11-22T13:03:31.000Z",
//             valid: true,
//             velocity: {
//                 groundSpeed: 0,
//                 heading: 0,
//             },
//         },
//     },
// ];

function transformGpsData(input: any[], date: Date) {
    const groupedData: Record<string, Record<string, number>> = {};
    const gpsIds = new Set<string>();

    input.forEach(entry => {
        const gpsId = entry.title;
        gpsIds.add(gpsId);

        const start = parseISO(entry.startTrackPoint.utc);
        const end = parseISO(entry.endTrackPoint.utc);

        const dateKey = format(start, "EEEE d. MMM", { locale: da }); // Danish locale
        const minutes = differenceInMinutes(end, start);

        if (!groupedData[gpsId]) {
            groupedData[gpsId] = {};
        }

        if (!groupedData[gpsId][dateKey]) {
            groupedData[gpsId][dateKey] = 0;
        }

        groupedData[gpsId][dateKey] += minutes;
    });

    // Generate a full week for each gpsId
    const result = Array.from(gpsIds).map(gpsId => {
        const startOfCurrentWeek = startOfWeek(date, { locale: da });
        const fullWeek = Array.from({ length: 7 }, (_, i) => {
            const date = addDays(startOfCurrentWeek, i);
            const formattedDate = format(date, "EEEE d. MMM", { locale: da });
            return {
                x: formattedDate,
                y: groupedData[gpsId]?.[formattedDate] || 0, // Default to 0 if no data for the day
            };
        });

        return {
            id: gpsId,
            data: fullWeek,
        };
    });

    return result?.sort((a, b) => a.id.localeCompare(b.id));
}
